import LookAndFeel from "~/helpers/LookAndFeel";

export const useMainStore = defineStore("main", {
    state() {
        return {
            color: "#1a2975",
            logo: null,
            name: "Accounting",
            company: null,
            currency: "GBP",
            user: {
                name: null,
                avatar: null,
                token: null,
                uuid: null
            },
            login_methods: ["email"],
            title: "",
            textColor: "#000000"
        };
    },
    getters: {},
    actions: {
        updateToken(token) {
            this.user.token = token;
        },
        updateUserDetails(name, avatar, uuid) {
            this.user.name = name;
            this.user.avatar = avatar;
            this.user.uuid = uuid;
        },
        updateCompanyDetails(color, logo, name, company, currency, login_methods) {
            this.color = color;
            this.logo = logo;
            this.name = name;
            this.company = company;
            this.currency = currency;
            this.login_methods = login_methods;

            const rgb = LookAndFeel.hex2Rgb(this.color);
            const defaultColorRgb = LookAndFeel.hex2Rgb(this.textColor);
            if (LookAndFeel.contrast(rgb, defaultColorRgb) < 7) {
                this.textColor = "#ffffff";
            }


            useHead({
                titleTemplate: "%s | " + this.name
            });
        },
        updateTitle(title) {
            this.title = title;
            useHead({
                title: title
            });
        }
    }
});