import { default as _91uuid_93Hqw1fpi6EGMeta } from "/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/[uuid].vue?macro=true";
import { default as _91uuid_93umJd0SjcyBMeta } from "/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/c/[uuid].vue?macro=true";
import { default as index26xdZ3OvWUMeta } from "/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/index.vue?macro=true";
import { default as loginj3YdcrGasbMeta } from "/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/login.vue?macro=true";
import { default as settingssRuM9vD6J0Meta } from "/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/settings.vue?macro=true";
import { default as welcomeG7yCAcsoSuMeta } from "/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/welcome.vue?macro=true";
export default [
  {
    name: _91uuid_93Hqw1fpi6EGMeta?.name ?? "uuid",
    path: _91uuid_93Hqw1fpi6EGMeta?.path ?? "/:uuid()",
    meta: _91uuid_93Hqw1fpi6EGMeta || {},
    alias: _91uuid_93Hqw1fpi6EGMeta?.alias || [],
    redirect: _91uuid_93Hqw1fpi6EGMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93umJd0SjcyBMeta?.name ?? "c-uuid",
    path: _91uuid_93umJd0SjcyBMeta?.path ?? "/c/:uuid()",
    meta: _91uuid_93umJd0SjcyBMeta || {},
    alias: _91uuid_93umJd0SjcyBMeta?.alias || [],
    redirect: _91uuid_93umJd0SjcyBMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/c/[uuid].vue").then(m => m.default || m)
  },
  {
    name: index26xdZ3OvWUMeta?.name ?? "index",
    path: index26xdZ3OvWUMeta?.path ?? "/",
    meta: index26xdZ3OvWUMeta || {},
    alias: index26xdZ3OvWUMeta?.alias || [],
    redirect: index26xdZ3OvWUMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginj3YdcrGasbMeta?.name ?? "login",
    path: loginj3YdcrGasbMeta?.path ?? "/login",
    meta: loginj3YdcrGasbMeta || {},
    alias: loginj3YdcrGasbMeta?.alias || [],
    redirect: loginj3YdcrGasbMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/login.vue").then(m => m.default || m)
  },
  {
    name: settingssRuM9vD6J0Meta?.name ?? "settings",
    path: settingssRuM9vD6J0Meta?.path ?? "/settings",
    meta: settingssRuM9vD6J0Meta || {},
    alias: settingssRuM9vD6J0Meta?.alias || [],
    redirect: settingssRuM9vD6J0Meta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: welcomeG7yCAcsoSuMeta?.name ?? "welcome",
    path: welcomeG7yCAcsoSuMeta?.path ?? "/welcome",
    meta: welcomeG7yCAcsoSuMeta || {},
    alias: welcomeG7yCAcsoSuMeta?.alias || [],
    redirect: welcomeG7yCAcsoSuMeta?.redirect || undefined,
    component: () => import("/builds/stantabcorp/comptabilite/expense-report-dashboard/pages/welcome.vue").then(m => m.default || m)
  }
]