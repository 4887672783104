import { useMainStore } from "~/stores/main";
import Cookies from "js-cookie";

export default defineNuxtRouteMiddleware((to, from) => {
    const store = useMainStore()

    if (store.user.token == null) {
        let token = Cookies.get("token")
        if (token !== undefined) {
            store.updateToken(token)
            if (to.fullPath.startsWith("/login")) {
                return navigateTo("/")
            }
        } else if ((!to.fullPath.startsWith("/login") && !to.fullPath.startsWith("/c/")) && to.query.login === undefined && to.query.code === undefined) {
            return navigateTo("/login")
        }
    }

})