import Cookies from "js-cookie";
import { useMainStore } from "~/stores/main";
import Accounting from "~/helpers/Accounting";

const RED = 0.2126;
const GREEN = 0.7152;
const BLUE = 0.0722;

const GAMMA = 2.4;

export default {

    hex2Rgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    },
    luminance(r, g, b) {
        const a = [r, g, b].map((v) => {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, GAMMA);
        });
        return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
    },
    contrast(rgb1, rgb2) {
        const lum1 = this.luminance(...rgb1);
        const lum2 = this.luminance(...rgb2);
        const brightest = Math.max(lum1, lum2);
        const darkest = Math.min(lum1, lum2);
        return (brightest + 0.05) / (darkest + 0.05);
    },

    load() {
        return new Promise((resolve, reject) => {
            const store = useMainStore();

            if (store.company === null) {
                let company = Cookies.get("company");
                if (company === "null") {
                    company = null;
                }
                let url = window.location.protocol + "//" + window.location.host;

                Accounting.post("/expenses/expense-reports/settings", {
                        company: company,
                        url: url
                    })
                    .then(res => {
                        const data = res.data;
                        store.updateCompanyDetails(data.color, data.logo, data.name, data.company, data.currency, data.login_methods);
                        Cookies.set("company", data.company);
                        resolve();
                    })
                    .catch(err => {
                        if (err.response !== undefined && err.response.data.errors.includes("The provided company must be a valid one") && company != null) {
                            Cookies.set("company", "null", {
                                expires: Date.now()
                            });
                            this.load()
                                .then(() => resolve())
                                .catch(() => reject());
                        } else {
                            reject();
                        }
                    });

            } else {
                resolve();
            }
        });
    }
};